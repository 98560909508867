import React, { useRef } from "react";
import Slider from "react-slick";
import { Link } from "gatsby";
const FeaturesSlider = ({ packagetype, sliderfeatures }) => {
  const settings = {
    infinite: true,
    arrows: false,
    dots: false,
    speed: 500,
    centerMode: true,

    slidesToShow: 5,
    centerPadding: "15%",

    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          centerPadding: "20%",
          centerMode: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          centerPadding: "30%",
          centerMode: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerPadding: "0%",
          centerMode: false,
        },
      },
    ],
  };
  const slider = useRef(null);
  const next = () => {
    slider.current.slickNext();
  };
  const previous = () => {
    slider.current.slickPrev();
  };
  return (
    <div className="mb-24 lg:mb-40 xl:mb-64 px-3">
      <div className="text-center">
        <h3 className="font-bold text-2xl  lg:text-4xl text-blackish">
          Plus all features from the{" "}
          {packagetype === "professional"
            ? "Essentials Package"
            : "Professional Package"}
        </h3>
        <p className="text-textcolor mt-4">Scroll to view more information</p>
      </div>
      <div className="lg:mt-16 py-4">
        <Slider {...settings} ref={slider}>
          {sliderfeatures.map((feature) => {
            return (
              <div
                className={`flex w-full  ${
                  packagetype === "professional"
                    ? "feature-slider-card-essential"
                    : "feature-slider-card-professional"
                } `}
              >
                <div className=" py-8 w-10/12 mx-auto">
                  <div className="w-3/5 md:w-4/5 mb-6 feature-slider-image mx-auto">
                    <img
                      alt={`${feature.title}-icon`}
                      src={feature.image.publicURL}
                    />
                  </div>
                  <h3 className="text-left text-blackish text-md font-bold text-center">
                    {feature.title}
                  </h3>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>

      <div className="max-w-6xl mx-auto px-5 mt-12">
        <div className="w-full md:w-1/2 mx-auto flex justify-between items-center">
          <div
            className="flex review-arrow-previous text-3xl pt-1 z-10 text-white items-center justify-center cursor-pointer btn-purple-on-white"
            onClick={() => {
              previous();
            }}
          >
            <img
              alt={`previous-arrow`}
              src={require("../media/angle-left.svg")}
            ></img>
          </div>
          <Link
            to={
              packagetype === "professional"
                ? "/package/essential"
                : "/package/professional"
            }
            className=" w-3/5 mx-auto lg:w-2/5"
          >
            <button
              className={`py-3 cursor-pointer focus:outline-none rounded-md border text-white bg-${
                packagetype === "professional"
                  ? "green btn-green-on-white"
                  : "pink btn-pink-on-white"
              }  px-auto block md:inline w-full`}
            >
              View more information
            </button>
          </Link>
          <div
            className="flex review-arrow-previous text-3xl z-10 text-white items-center justify-center cursor-pointer btn-purple-on-white"
            onClick={() => {
              next();
            }}
          >
            <img
              alt="next-arrow"
              src={require("../media/angle-right.svg")}
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSlider;
