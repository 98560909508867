import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";

import { AnchorLink } from "gatsby-plugin-anchor-links";

import Includes from "../views/homepage/includes";
import FeaturesSlider from "../components/FeaturesSlider";
const Package = ({ data }) => {
  const {
    path,
    title,
    subtitle,
    theme,
    price,
    buttontext,
    image,
    whatyouget,
    icons,
    preview_url,
    features_heading,
    features,
  } = data.markdownRemark.frontmatter;
  const { scroll } = data.Scroll.frontmatter;
  const { edges } = data.AllFeatures;

  return (
    <Layout>
      <SEO
        seo={{
          title: title,
          description: subtitle,
          previewURL: preview_url,
        }}
      />
      <div id="package-main">
        <div className="max-w-6xl mx-auto sm:h-screen px-5 md:px-8">
          <div className="md:flex flex-col justify-center h-full relative">
            <div className="w-full flex flex-wrap mt-20 items-center">
              <div className="w-full md:w-2/3 xl:w-1/2 md:pr-10 mb-8 md:mb-0">
                <div className="flex mt-16 mb-4 w-1/2">
                  {icons.map((iconobject) => {
                    return (
                      <AnchorLink
                        className="cursor-pointer mr-4 w-2/3 md:w-1/6"
                        to={`/${iconobject.path}`}
                      >
                        <img
                          className={`${
                            iconobject.include ? "opacity-100" : "opacity-25"
                          }`}
                          src={iconobject.icon.publicURL}
                          alt={`${title}-icon`}
                        ></img>
                      </AnchorLink>
                    );
                  })}
                </div>
                <h2 className={`text-2xl font-bold md:text-5xl text-${theme}`}>
                  {title}
                </h2>
                <p className="text-lg text-textcolor mt-8">{subtitle}</p>
                <p className="my-6 md:my-12 text-lg font-bold headertext">
                  {price}
                </p>
                <div className="mt-10 md:mt-16">
                  <AnchorLink
                    to={`${path}/#whatyouget`}
                    className="py-3 w-full"
                    stripHash
                  >
                    <button className="bg-white text-sm border border-theme  text-theme hover:text-buttonpurplehover rounded-md w-2/5 md:w-1/3 py-3 mr-3 md:mr-12 outline-none">
                      Learn more
                    </button>
                  </AnchorLink>
                  <a
                    rel="noreferrer noopener"
                    href="https://app.merlinpanel.com/"
                    target="_blank"
                  >
                    <button
                      className={`bg-${theme} btn-${theme}-on-white text-sm  text-white hover:text-buttonwhitehover rounded-md w-1/2 md:w-2/5 py-3 outline-none`}
                    >
                      {buttontext}
                    </button>
                  </a>
                </div>
              </div>
              <div className="w-full md:w-1/3 xl:w-1/2">
                <div className="w-2/3 md:w-full xl:w-2/3 mx-auto md:ml-auto md:mr-0">
                  <img alt={`${title}-preview`} src={image.publicURL} />
                </div>
              </div>
            </div>
            <div className="hidden md:flex h-32 absolute bottom-0 w-full items-center justify-center mt-12">
              <AnchorLink to={`${path}/#whatyouget`} stripHash>
                <img
                  alt={`${title}-preview`}
                  className="cursor-pointer"
                  src={scroll.publicURL}
                ></img>
              </AnchorLink>
            </div>
          </div>
        </div>
        <div
          className="max-w-6xl mx-auto py-12 text-center px-5 md:px-8"
          id="whatyouget"
        >
          <h2 className="text-2xl md:text-4xl font-bold text-center">
            What you get
          </h2>
          <div className="flex justify-between flex-wrap py-4 md:py-24">
            {whatyouget.map((val) => {
              return (
                <Link className="w-full md:w-5/12" to={val?.path}>
                  <div className="w-full  px-8 py-12 my-4 feature-card ">
                    <div>
                      <div className="flex my-6 items-center">
                        <div className="w-1/4">
                          <div
                            className={`bg-${theme} rounded-full flex items-center justify-center icon-holder`}
                          >
                            <img src={val.icon.publicURL} alt="icon" />
                          </div>
                        </div>
                        <div className="w-3/4 flex justify-start">
                          <h2 className="inline-block my-1 mx-5  text-xl md:text-2xl font-bold">
                            {val.title}
                          </h2>
                        </div>
                      </div>
                      <p className="text-md text-textcolor text-left">
                        {val.discription}
                      </p>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
        <Includes ispackage />

        <div className="max-w-6xl mx-auto text-center px-5 md:px-8 py-8 md:py-16 ">
          <h3 className="font-bold text-blackish text-2xl md:text-4xl">
            {features_heading}
          </h3>
          <div className="w-full mt-4 md:12 p-6 md:p-10 lg:p-12 flex flex-wrap  justify-between">
            {features &&
              features.map((feature, index) => (
                <div
                  key={index}
                  className="w-full sm:w-5/12 md:w-2/5 lg:w-3/12 md:mx-1 mb-24"
                >
                  <div className="w-3/5 md:w-4/5 mb-8 md:mb-16 underpackage-feature-img">
                    <img
                      alt={`${title}-preview`}
                      src={feature.image.publicURL}
                    />
                  </div>
                  <h3 className="text-left text-blackish text-lg font-bold">
                    {feature.title}
                  </h3>
                  <p className="text-left text-textcolor mt-6">
                    {feature.discription}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {path === "/package/professional" ? (
          <FeaturesSlider
            packagetype="professional"
            sliderfeatures={edges[0].node.frontmatter.features}
          />
        ) : path === "/package/commercial" ? (
          <>
            <FeaturesSlider
              packagetype="commercial"
              sliderfeatures={edges[1].node.frontmatter.features}
            />
            <FeaturesSlider
              packagetype="professional"
              sliderfeatures={edges[0].node.frontmatter.features}
            />
          </>
        ) : (
          <></>
        )}

        <div className="max-w-6xl mx-auto text-center px-5 md:px-8 py-8 md:py-16 mt-16">
          <h2 className="font-bold text-blackish text-2xl md:text-4xl">
            Ready to cast a spell over your website?
          </h2>
          <div className="text-center mt-10">
            <Link to="/about">
              <button className="py-3 cursor-pointer shadow focus:outline-none rounded-md border text-theme border-theme  px-auto md:mr-6 block md:inline w-3/5 mx-auto md:w-1/4 lg:w-1/6 mb-3 md:mb-0">
                Learn more
              </button>
            </Link>
            <a
              rel="noreferrer noopener"
              href="https://app.merlinpanel.com/"
              target="_blank"
            >
              <button
                className={`py-3 cursor-pointer shadow focus:outline-none rounded-md text-white bg-${theme} btn-${theme}-on-white mb-3  px-auto md:mr-6 block md:inline w-3/5 mx-auto md:w-1/3 lg:w-1/5`}
              >
                {buttontext}
              </button>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        path
        theme
        price
        preview_url
        buttontext
        image {
          publicURL
        }
        icons {
          icon {
            publicURL
          }
          include
          path
        }

        whatyouget {
          title
          path
          discription
          icon {
            publicURL
          }
        }
        features_heading
        features {
          title

          image {
            publicURL
          }
          discription
        }
      }
    }
    Scroll: markdownRemark(fileAbsolutePath: { regex: "/package_main/" }) {
      frontmatter {
        scroll: scroll_indicator {
          publicURL
        }
      }
    }
    AllFeatures: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/packages/" } }
      sort: { fields: [frontmatter___id], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            id
            features {
              title
              image {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;

export default Package;
